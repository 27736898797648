<template>
  <piepkaart-wms-layer
    url="https://geo.piepkaart.nl/geoserver/wms"
    layers="verboden_zoekgebieden"
    name="Verboden zoekgebieden"
    layer-type="verboden"
    :z-index="10"
    :options="{ zIndex: 10 }"
    :bring-to-front="true"
  ></piepkaart-wms-layer>
</template>
<script>
import PiepkaartWmsLayer from "@/components/PiepkaartWmsLayer.vue";
export default {
  name: "verboden-zoekgebieden",
  components: {
    PiepkaartWmsLayer,
  },
};
</script>
