<template>
  <div>
    <l-wms-tile-layer
      :base-url="baseUrl"
      :layers="layers"
      :visible="visible"
      :name="name"
      :transparent="transparent"
      :format="format"
      :layer-type="layerType"
      :tms="tms"
      :z-index="zIndex"
      :options="options"
      :crs="crs"
    >
    </l-wms-tile-layer>
  </div>
</template>
<script>
import { LWMSTileLayer } from "vue2-leaflet";
export default {
  components: { "l-wms-tile-layer": LWMSTileLayer },
  props: {
    url: {
      type: String,
      required: true,
    },
    sldBody: {
      type: String,
      default: null,
    },
    format: {
      type: String,
      default: "image/png",
    },
    layerType: {
      type: String,
      default: "base",
    },
    visible: {
      type: Boolean,
      default: true,
    },
    layers: {
      type: String,
      required: true,
    },
    transparent: {
      type: Boolean,
      default: true,
    },
    tms: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      required: true,
    },
    zIndex: {
      type: Number,
    },
    crs: {
      type: String,
    },
    bringToFront: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: null,
    };
  },
  computed: {
    baseUrl() {
      let url = this.url;

      if (this.sldBody) {
        url += `?SLD_BODY=${encodeURIComponent(this.sldBody)}`;
      }

      return url;
    },
    zoom() {
      return this.$store.state.zoom;
    },
    options() {
      return {
        onEachFeature: this.onEachFeature,
        pointToLayer: this.pointToLayer,
        filter: this.filter,
      };
    },
  },
};
</script>
