<template>
  <div style="height: 100%; width: 100%">
    <l-map
      :zoom="zoom"
      :center="center"
      :maxBounds="maxBounds"
      @update:zoom="zoomUpdated"
      @update:center="centerUpdated"
      @update:bounds="boundsUpdated"
      @ready="mapReady"
      style="height: 100%; width: 100%; z-index: 4"
      ref="map"
    >
      <l-control-layers
        position="topleft"
        @ready="onControlLayersReady"
      ></l-control-layers>
      <l-tile-layer
        v-for="tileProvider in tileProviders"
        :key="tileProvider.name"
        :name="tileProvider.name"
        :visible="tileProvider.name === tileLayer"
        :url="tileProvider.url"
        :attribution="tileProvider.attribution"
        layer-type="base"
      />
      <slot :bounds="bounds" :minLoadDataZoom="minLoadDataZoom"></slot>
      <restricted-areas
        v-if="pinRestrictedAreas || showRestrictedAreas"
      ></restricted-areas>
      <l-control>
        <search-input @location="onSearchLocation"></search-input>
      </l-control>
      <l-control v-if="legend" position="topright">
        <div
          v-if="showLegend"
          class="d-flex flex-column legend"
          @click="showLegend = !showLegend"
        >
          <h4 class="mb-1">{{ name }}</h4>
          <div class="my-1" v-for="(item, index) in legend" :key="index">
            <i :style="{ backgroundColor: item.color }"></i> {{ item.label }}
          </div>
        </div>
        <div v-else class="legend-icon" @click="showLegend = !showLegend">
          <v-icon dense>mdi-map-legend</v-icon>
        </div>
      </l-control>
      <!-- <l-control position="topright">
        <a :href="googleMapsUrl" class="legend-icon" target="_blank">
          <v-icon dense>mdi-google-maps</v-icon>
        </a>
      </l-control> -->
      <l-marker v-if="searchLocation" :lat-lng="searchLocation"> </l-marker>
    </l-map>
    <v-snackbar color="info" v-model="showWarning" timeout="-1" app>
      Zoom in om de data te laden

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="
            setZoom();
            showWarning = false;
          "
        >
          Zoom in
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import {
  LMap,
  LTileLayer,
  LControlLayers,
  LControl,
  LMarker,
} from "vue2-leaflet";
import SearchInput from "@/components/SearchInput.vue";
import RestrictedAreas from "@/components/RestrictedAreas.vue";
import L from "leaflet";
export default {
  data() {
    return {
      showWarning: false,
      showLegend: false,
      wakeLock: false,
      data: null,
      bbox: null,
      bounds: null,
      searchLocation: null,
      tileProviders: [
        {
          name: "Kaart",
          visible: true,
          attribution:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> gebruikers',
          url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        },
        {
          name: "Satteliet",
          visible: false,
          url: "https://service.pdok.nl/hwh/luchtfotorgb/wmts/v1_0/Actueel_ortho25/EPSG:3857/{z}/{x}/{y}.jpeg",
          attribution:
            '<a href="https://creativecommons.org/licenses/by/4.0/deed.nl">CC BY 4.0</a> Kadaster',
        },
      ],
    };
  },
  components: {
    LMap,
    LTileLayer,
    LControlLayers,
    LControl,
    SearchInput,
    RestrictedAreas,
    LMarker,
  },
  props: {
    name: {
      type: String,
    },
    maxBounds: {
      type: Array,
      default: () => [
        [49.803721015, 2.31497114423],
        [54.5104033474, 8.09205325687],
      ],
    },
    legend: {
      type: Array,
    },
    minLoadDataZoom: {
      type: Number,
      default: 14,
    },
    geoJson: {
      type: Object,
    },
    minRefetchDistance: {
      type: Number,
      default: 500,
    },
  },
  methods: {
    onSearchLocation(search) {
      this.searchLocation = [search.location.y, search.location.x];
      this.centerUpdated([search.location.y, search.location.x]);
    },
    zoomUpdated(zoom) {
      this.$store.commit("zoom", zoom);
    },
    centerUpdated(center) {
      this.$store.commit("center", center);
    },
    boundsUpdated(bounds) {
      if (this.bbox === bounds.toBBoxString()) return;

      this.bounds = bounds;
      this.bbox = bounds.toBBoxString();
      this.oldBounds = bounds;
      this.showWarning = this.zoom < this.minLoadDataZoom;
    },
    mapReady() {
      this.bbox = this.$refs.map.mapObject.getBounds().toBBoxString();
      this.bounds = this.$refs.map.mapObject.getBounds();
      this.oldBounds = this.$refs.map.mapObject.getBounds();
      this.showWarning = this.zoom < this.minLoadDataZoom;
    },
    setZoom() {
      this.$refs.map.mapObject.setZoom(this.minLoadDataZoom);
    },
    onControlLayersReady(e) {
      e._layersLink.innerHTML = `<span class="mdi mdi-layers"></span>`;
    },
  },
  computed: {
    center() {
      return this.$store.state.center;
    },
    zoom() {
      return this.$store.state.zoom;
    },
    tileLayer() {
      return this.$store.state.tileLayer;
    },
    showRestrictedAreas() {
      return this.$store.state.showRestrictedAreas;
    },
    pinRestrictedAreas() {
      return this.$store.state.pinRestrictedAreas;
    },
    googleMapsUrl() {
      return `https://www.google.nl/maps/@${this.center.lat},${this.center.lng},${this.zoom}z/data=!3m1!1e3`;
    },
  },
  async mounted() {
    L.control
      .locate({
        icon: "mdi mdi-crosshairs-gps",
        iconLoading: "mdi mdi-loading mdi-spin",
        keepCurrentZoomLevel: true,
        // setView: "once",
        // flyTo: true,
        locateOptions: {
          enableHighAccuracy: true,
        },
      })
      .addTo(this.$refs.map.mapObject);

    this.$nextTick(() => {
      this.$refs.map.mapObject.on("popupopen", (e) => {
        this.$emit("popupopen", e.popup._source);
      });

      this.$refs.map.mapObject.on("baselayerchange", (e) => {
        this.$store.commit("tileLayer", e.name);
      });

      this.$refs.map.mapObject.attributionControl.setPrefix("Piepkaart.nl");
    });

    if ("wakeLock" in navigator) {
      try {
        this.wakeLock = await navigator.wakeLock.request("screen");
      } catch (err) {
        this.wakeLock = "error: " + err;
        // The Wake Lock request has failed - usually system related, such as battery.
      }
    } else {
      this.wakeLock = "Niet beschikbaar";
    }
  },
};
</script>
<style>
/* .leaflet-control-layers-toggle {
  width: 26px;
  height: 26px;
  
} */

.leaflet-retina .leaflet-control-layers-toggle,
.leaflet-control-layers-toggle {
  background-image: none;
  font-size: 1.4em;
  color: #444;
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  cursor: pointer;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
</style>
<style scoped>
.legend-icon {
  color: #444;
  padding: 5px;
  font: 1.1em Arial, Helvetica, sans-serif;
  background: white;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}
.legend {
  color: #555;
  padding: 6px 8px;
  font: 1.1em Arial, Helvetica, sans-serif;
  background: white;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-clip: padding-box;
}
.legend i {
  width: 14px;
  height: 14px;
  float: left;
  margin-right: 6px;
  border-radius: 50%;
}
</style>
