<template>
  <div>
    <v-autocomplete
      v-if="showSearch"
      v-model="model"
      :items="items"
      :loading="isLoading"
      :search-input.sync="search"
      hide-no-data
      hide-details
      hide-selected
      item-text="text"
      item-value="magicKey"
      placeholder="Plaats of adres"
      return-object
      solo
      clearable
      dense
    >
      <template v-slot:append>
        <div @click="showSearch = !showSearch">
          <v-icon dense>mdi-magnify-close</v-icon>
        </div>
      </template>
    </v-autocomplete>
    <div v-else class="search-icon" @click="showSearch = !showSearch">
      <v-icon dense>mdi-magnify</v-icon>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    count: 0,
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    model: null,
    search: null,
    isEditing: false,
    showSearch: false,
  }),

  computed: {
    fields() {
      if (!this.model) return [];

      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || "n/a",
        };
      });
    },
    items() {
      return this.entries.map((entry) => {
        const text =
          entry.text.length > this.descriptionLimit
            ? entry.text.slice(0, this.descriptionLimit) + "..."
            : entry.text;

        return Object.assign({}, entry, { text });
      });
    },
  },

  watch: {
    model(val) {
      if (val === null) return;
      fetch(
        `https://geocoder.arcgisonline.nl/arcgis/rest/services/Geocoder_BAG_RD/GeocodeServer/findAddressCandidates?SingleLine=${val.text}&outSR=4326&magicKey=${val.magicKey}&f=json`
      )
        .then((res) => res.json())
        .then((res) => {
          const { candidates } = res;
          const location = candidates[0];
          this.$emit("location", location);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
          this.showSearch = false;
        });
    },
    search(val) {
      // Items have already been loaded
      // if (this.items.length > 0) return;
      if (val === null) return;

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items
      fetch(
        `https://geocoder.arcgisonline.nl/arcgis/rest/services/Geocoder_BAG_RD/GeocodeServer/suggest?f=json&text=${val}&maxSuggestions=6`
      )
        .then((res) => res.json())
        .then((res) => {
          // console.log(res);
          const { suggestions } = res;
          this.count = suggestions.length;
          this.entries = suggestions;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
<style scoped>
.search-icon {
  color: #444;
  padding: 5px;
  font: 1.1em Arial, Helvetica, sans-serif;
  background: white;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-clip: padding-box;
}

.v-input__append-outer {
  margin-top: 0 !important;
}
</style>
